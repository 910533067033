<template>
  <div class="textmessage">
    <div v-if="detalform == 'kuaidi100'">
      <div class="textmessage_bottom">
        <el-form v-show="kflsre" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px"
          class="demo-ruleForm">
          <el-form-item label-width="190px" label="快递100授权KEY：" :prop="'express-kuaidi100-api_key'">
            <el-input clearable v-model.trim="ruleForm['express-kuaidi100-api_key']"></el-input>
          </el-form-item>

          <el-form-item label-width="190px" label="快递100Customer：" :prop="'express-kuaidi100-api_customer'">
            <el-input clearable v-model.trim="ruleForm['express-kuaidi100-api_customer']" class="diy--big"></el-input>
          </el-form-item>

          <el-form-item label-width="190px" label="快递100SerCet：" :prop="'express-kuaidi100-secret'">
            <el-input clearable v-model.trim="ruleForm['express-kuaidi100-secret']" class="diy--big"></el-input>
          </el-form-item>
        </el-form>
        <!-- <el-button class="bntout" type="primary">保存</el-button> -->
      </div>
    </div>
    <!-- /////////////////////////////快递鸟////////////////////////////////////////// -->
    <div v-if="detalform == 'kuaidiniao'" class="textmessage_bottom_del">
      <div class="textmessage_bottom">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
          <el-form-item sty label-width="180px" label="快递鸟授权KEY：" :prop="'express-kuaidiniao-api_key'">
            <el-input clearable v-model.trim="ruleForm['express-kuaidiniao-api_key']" class="diy--big"></el-input>
          </el-form-item>

          <el-form-item label-width="180px" label="快递鸟授权id：" :prop="'express-kuaidiniao-api_id'">
            <el-input clearable v-model.trim="ruleForm['express-kuaidiniao-api_id']"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <diy-footer-button>
      <el-button type="primary" @click="edit">保存</el-button>
    </diy-footer-button>
  </div>
</template>

<script>
export default {
  props: ["detalform"],
  data() {
    return {
      kflsre: true,
      value: true,
      ruleForm: {},
      rules: {
        "express-kuaidi100-api_key": [{ required: true, message: "请输入快递100授权KEY", trigger: "blur" }],
        "express-kuaidi100-api_customer": [{ required: true, message: "请输入快递100Customer", trigger: "blur" }],
        "express-kuaidi100-secret": [{ required: true, message: "请输入快递100SerCet", trigger: "blur" }],
        'express-kuaidiniao-api_id': [{ required: true, message: "请输入快递鸟授权id", trigger: "blur" }],
        'express-kuaidiniao-api_key': [{ required: true, message: "请输入快递鸟授权KEY：", trigger: "blur" }]
      }
    };
  },
  created() {
    this.excorp();
  },
  methods: {
    // 获取物流信息
    excorp() {
      let that = this;
      this.$get(this.$apis.exprekd).then(res => {
        if (res.code == 200) {
          this.ruleForm = res.data.result;
          // a.b.c -> a-b-c
          let handleFrom = {}
          Object.keys(res.data.result).forEach(item => {
            handleFrom[item.split('.').join('-')] = res.data.result[item]
          })
          this.ruleForm = handleFrom;
          that.$parent.radio = res.data.result['express.type'];
          // //console.log()
        } else {
          this.common.message(this, res.message);
        }
      }).catch(err => {
        // //console.log(err)
        this.common.message(this, err.message);
      });
    },
    // 修改物流信息
    edit() {
      this.ruleForm["express.type"] = this.detalform;
      // a-b-c -> a.b.c 
      let responseFrom = {};
      Object.keys(this.ruleForm).forEach(item => {
        responseFrom[item.split('-').join('.')] = this.ruleForm[item]
      })
      this.$put(this.$apis.exprekd, responseFrom)
        .then(res => {
          if (res.code == 200) {
            this.$message({
              message: "保存成功",
              type: "success"
            });
          } else {
            this.common.message(this, res.message);
          }
        })
        .catch(err => {
          this.common.message(this, err.message);
        });
    },
    inpubtn() {
      this.kflsre = !this.kflsre;
    },
    fbuton() {
      this.$router.push("/setup/setup_home");
    },
    butonalli() {
      this.$router.push("/setup/textmessage/alibaba");
    }
  }
};
</script>

<style lang="less" scoped>
.textmessage {


  // height: 362px;
  .theswitch {
    display: flex;
    margin-left: 100px;
  }


  .textmessage_top {
    margin-bottom: 30px;
    border-radius: 4px;
    background: #fff;
    padding: 15px;
    box-sizing: border-box;
  }


  .textmessage_bottom {
    .el-form {
      margin-top: 8px;
    }


    // height: 300px;
    border-radius: 4px;
    background: #fff;
    padding: 15px;
    box-sizing: border-box;


    // margin-bottom:100px;
    p {
      width: 448px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }


    span {
      height: 20px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
    }


    .textmessage_bottom_text {
      margin-bottom: 38px;
    }


    .iconconte {
      display: flex;
      margin: 20px;
      justify-content: space-around;


      .iconconte_one {
        width: 280px;
        height: 74px;
        background: #ffffff;
        border-radius: 3px;
        border: 1px solid #dddfe6;
        display: flex;
        align-items: center;
        margin: 0 12px;


        img {
          margin-left: 30px;
          width: 39px;
          height: 43px;
        }


        .iconconte_text {
          margin-left: 33px;


          .Interface {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            margin-left: 5px;
            color: #0052d9;
            cursor: pointer;
          }
        }


        /deep/ .el-radio__label {
          display: none;
        }
      }
    }


  }

  .textmessage_bottom_del {


    // height: 362px;
    .theswitch {
      display: flex;
      margin-left: 100px;
    }


    .textmessage_top {
      margin-bottom: 30px;
      border-radius: 4px;
      background: #fff;
      padding: 15px;
      box-sizing: border-box;
    }


    .textmessage_bottom {
      .el-form {
        margin-top: 8px;
      }


      // height: 300px;
      border-radius: 4px;
      background: #fff;
      padding: 15px;
      box-sizing: border-box;


      // margin-bottom:100px;
      p {
        width: 448px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }


      span {
        height: 20px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.9);
      }


      .textmessage_bottom_text {
        margin-bottom: 38px;
      }


      .iconconte {
        display: flex;
        margin: 20px;
        justify-content: space-around;


        .iconconte_one {
          width: 280px;
          height: 74px;
          background: #ffffff;
          border-radius: 3px;
          border: 1px solid #dddfe6;
          display: flex;
          align-items: center;
          margin: 0 12px;


          img {
            margin-left: 30px;
            width: 39px;
            height: 43px;
          }


          .iconconte_text {
            margin-left: 33px;


            .Interface {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              margin-left: 5px;
              color: #0052d9;
              cursor: pointer;
            }
          }


          /deep/ .el-radio__label {
            display: none;
          }
        }
      }

    }


    .addtion_foot {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      padding-left: 160px;
      box-sizing: border-box;
      height: auto;
      border-top: 1px solid #f1f1f1;
      z-index: 6;
      background-color: #fff;


      .addtion_foot-body {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px !important;
      }
    }
  }


  .addtion_foot {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding-left: 160px;
    box-sizing: border-box;
    height: auto;
    border-top: 1px solid #f1f1f1;
    z-index: 6;
    background-color: #fff;


    .addtion_foot-body {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px !important;
    }
  }
}
</style>